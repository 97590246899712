/*
 * Globals
 */
@media all and (max-device-width: 500px){
    .nav{
        font-size: 10px !important;
    }
    p{
        font-size: 10px !important;
    }
    ul.menu{
        padding: 5px 0px 0px 20px !important;
    }
    th {
        font-size: 8px !important;
    }
    img { max-width: 80%; }
}
* {
   /*border: 1px solid rgba(0, 0, 0, 0.5);*/
   table-layout: fixed;
}
/*img:hover {
    opacity: 0.5;
    cursor: pointer; 
    cursor: hand;
    filter: alpha(opacity=50); 
}*/
html,body {
    font-family: 'Futura Std',  'Futura', 'Futura-Medium', "Century Gothic", 'CenturyGothic', "Apple Gothic", 'AppleGothic', "URW Gothic L", "Avant Garde", sans-serif;
    color: #FFFFFF;
    margin: 0;
    background: url("/image/BGv2.png") no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: #06224C;
    box-sizing: border-box;
    height: 100%; 
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: white !important;
}


input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}

.fp-viewing-main #ptalogo,
.fp-viewing-main #moveTop
 {
    display: none;
}
.fp-viewing-about_name #moveDown
{
    display: none;
}
.header {
    width: 100%;
    height: 8.5%;
    position: relative;
    z-index: 1000;
    top: 2.93%;
    display: inline-block;
    box-sizing: border-box;
}
.brand-image {
    top:30%;
    left: 4%;
    height: 100%;
    position: absolute;
    box-sizing: border-box;
}

.about-brand_image {
    top:-6%;
    left: 4%;
    height: 100%;
    position: absolute;
    box-sizing: border-box;
}

.nav {
    font-size: 14px ;
    width: 27.58%;
    min-width: 280px;
    height: 30%;
    right:3%;
    top:40%;
    position: absolute;
    box-sizing: border-box;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 0.8em;
    letter-spacing: 2px;
    color: #91A6B2;
    display: inline-block;
    text-align: justify;
}


ul.menu {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 10px 0px 0px 0px;
}



.flex-center {
    /*width: 100%;*/
    height: 83%; /* For at least Firefox */
   /* min-height: 90%;*/
    align-items: center;
    -ms-flex-pack: center;/*Center horizontaly ie */
    -ms-flex-align:center;
    display: flex;
    justify-content: center;
    /*top:8%;*/
    position:relative;
    float: inherit;
}

.contact-center {
    height: 83%; 
    align-items: center;
    -ms-flex-pack: center;
    -ms-flex-align:center;
    justify-content: center;
    top:15%;
    position:relative;
    float: inherit;
}


.side-slider {
    height: 70%; /* For at least Firefox */
    /*min-height: 90%;*/
    align-items: center;
    -ms-flex-pack: center;/*Center horizontaly ie */
    -ms-flex-align:center;

    display: flex;
    top:8%;
    /*left: 2.73%;*/
    position:relative;
    z-index: 1000;
    margin-left: 1.5em;
    float: left;

   /* top: 0;
    left: -32%;
    position: relative;
    z-index: 1000;*/
}
.down-arrow {
    width: 100%;  
    align-items: center;
    -ms-flex-pack: center;/*Center horizontaly ie */
    -ms-flex-align:center;
    display: flex;
    justify-content: center;

    top:81.25%;

    bottom: 0;
    position:absolute;
    z-index: 1000;

}
/*.arrow {
    font-size: 40px;
    font-weight: 900;
    padding-left: 6px;
    padding-right: 6px;
    color: #06224C;
}*/
/*.arrow-border {
    border-radius: 50%;
    background-color: #9FA3A7;
    opacity: 0.1;
    padding-left: 2px;
    padding-right: 2px;
}
.arrow-border:hover {
    opacity: 0.05;
    cursor: pointer;
    cursor: hand;
    filter: alpha(opacity=50);
}*/

/*i.arrow-up {
    font-size:30px;
    text-align: center;
    color: #91A6B2;
}
p.arrow-up {
    text-align: center;
    font-size: 10px;
    letter-spacing: 2px;
    font-family: 'Open Sans', sans-serif;
    color: #91A6B2;
    margin-top: -12px;
}*/
.arrow:hover {
    opacity: 0.5;
    cursor: pointer;
    cursor: hand;
    filter: alpha(opacity=50);
}


a {
    color: #91A6B2;
    /*padding: 0 25px;
    */
    /*font-weight: 900;
    letter-spacing: 2px;*/
    text-decoration: none;
    text-transform: uppercase;
    
}

a:focus, a:hover, a:active{
    color: #FFFFFF;
    text-decoration: none;
}

li.active > a {
    color: #FFFFFF;
}


p.ptabout {
    width: 65.6%;
    font-size: 1.26em;
    font-weight: 300;
    text-align: justify;
    line-height: 1.62em;
}

.back-to-top {
    /*width: 100%; */ 

    display: flex;

    top:87.97%;
    left: 92.42%; 
    position:absolute;
    z-index: 1000;
}


.partnership {
    width:80%;
    height:60%;
    /*width: 64.84%;
    min-width: 830px;
    height: 60.74%;
    min-height: 280px;
    text-align: justify;
    position: relative;*/
}
.redefined {
    width: 100%;
    height: 77.13%;
    background-color: rgba(56,80,120,0.3);
    border-radius: 8px;
    position: relative;
    align-items: center;
    -ms-flex-pack: center;/*Center horizontaly ie */
    -ms-flex-align:center;

    display: flex;
}

.redefined-h {
    width: 100%;
    top: 15.71%;
    position: absolute;
    align-items: center;
    -ms-flex-pack: center;/*Center horizontaly ie */
    -ms-flex-align:center;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    line-height: 19px;
    /*letter-spacing: 2px;*/
    text-transform: uppercase;

}

.redefined-icon{
    width: 100%;
    height: 47.5%;
    top: 34.29%;
    /*display:table;*/
    position: absolute;
    align-items: center;
    -ms-flex-pack: center;/*Center horizontaly ie */
    -ms-flex-align:center;
    text-align: center;
    display: flex;
}

#redefined-icon{
    width: 81.39%
}

table {
    /*width: 81.39%*/
   height:100%; 
   max-height: 100%;
   margin-top:0;
   margin-bottom: 0;
   margin-right:auto;
   margin-left: auto; 
}

tr:first-child {
    height: 75.93%;
}

th {
    text-align: center;
    /*font-size: 12px;*/
    font-weight: 900;
    text-align: center;
    vertical-align:top;
    /*line-height: 16px;*/
    color: #FFFFFF;
    text-transform: uppercase;
}

.btn-request-brochure {
    color: #FFFFFF;
    background-color: #509DC8;
    margin-top: 30px;
    border-radius: 49.5px;
    width: 230px;
    padding: 15px;
    font-weight: bold;
}

.btn-send {
    color: #FFFFFF;
    background-color: #509DC8;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 49.5px;
    width: 230px;
    padding: 15px;
    font-weight: bold;
}

.btn:hover {
    color: #E6E6E6;
    background-color: #1D6A95;
}

.text-box {
    position: absolute;    
    line-height: 100%;
    width: 100%;
    text-align: center;   
    vertical-align: middle; 
    margin:auto;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
    font-size: 0.8em;

}
/*.icon-brochure {
    background-image: url("/image/Button_RequestBrochure.png");
    background-position: center center;
}
*/

.contact-form {
    font-size: 1em;
    width: 57.84%;
    /*min-width: 741px;*/
    height: 77.64%;
    /*min-height: 464px;*/
    position: relative;

/*    padding: 0px; 
    display: inline-block;*/
}
.contact-form > * {
    /*float: none;*/
    display: inline-block;
   /* font-size: 1em;*/
   height: 100%;
}

.contact-form > *:last-child {
   /* height: 100%;*/
    /*margin-top: 40%;*/
    /*margin-left: 50px;*/

}
/*
.contact-form p:last-child {
    margin-bottom: 0;  optional 
    font-weight: 300;
    line-height: 23px;
    padding: 0;
}*/

.form-area {
    height: 100%;
    width: 55.47%;
    left: 0;
    top: 2.5em;
    position:absolute;
    /*display: flex;*/
}  

.form {
    height: 100%;
}


.contact-info {
    margin-top: 135px;
    margin-left: 80px;
    text-align: left;
    line-height: 30px;
    color: #FFFFFF;
}



.contact_info p {
    margin: 0 0 2em;
    font-size: 0.9em;
}
.address p{
    margin-bottom: 0.5em;
}

p.map {
    font-size: 0.8em;
    font-weight: 900;
    line-height: 1.17em;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    color: #509DC8;
}
.map:hover {
    color: #1D6A95;
}
.form-control{
    box-sizing: border-box;
    border-radius: 5px;
    background-color: rgba(56,80,120,0.3);
    opacity: 1;
    height: 2.92em;
    font-size: 1em;
    color: #FFF;
    border: none;
}

.contact_form .form-group,
.login_form .form-group {
    margin-bottom: 1em;
}



.contact_form .form-control,
.login_form .form-control {
    box-sizing: border-box;
    border-radius: 5px;
    background-color: rgba(56,80,120,0.3);
    opacity: 1;
    height: 2.92em;
    font-size: 1em;
    color: #FFF;
    border: none;
}


/*.asterisk_input:after {
    padding-right: 10px;
    background-image: url("/image/UI_Asterisk_grey.png")
    background-position: right;
}*/

.row {
    margin-right: 0px;
    margin-left: 0px; 

}
.name-row {
    margin-bottom: 0;
}

label {
    padding: 0;
    /*font-family: 'Futura Std',  'Futura', 'Futura-Medium',"Century Gothic", 'CenturyGothic', "Apple Gothic", 'AppleGothic', "URW Gothic L", "Avant Garde", sans-serif;*/
    font-size: 0.67em;
    font-weight: 900;
    letter-spacing: 3px;
    color: #91A6B2;
    line-height: 4.1em;
    text-transform: uppercase;
}

label.radiobtn,label.asterisk {
    line-height: 22px;
    top:0;
    position:absolute;
}

label.radiobtn2, label.f_password {
    color: #FFFFFF;
    font-size: 0.87em;
    font-weight: 500;
    line-height: 20px;
    top:0;
    position:absolute;
    text-transform: none;
    letter-spacing: 0;   
}

.radiobtn{
    left: 40px;
}
.asterisk{
    right: 0;
}

textarea#message {
    resize: none;
}

.contact-success {
    width: 329px;
    height: 200px;
    background-color: rgba(56,80,120,0.3);
    align-items: center;
    justify-content: center;
    text-align: center;
    align-content: center;
    float: right;
    /*margin-right: 80px;*/
    margin-top: 135px;
    border-radius: 5px;
  }
.checkmark-circle {
    border: 2px solid #509DC8;
    border-radius: 50%;
    padding-top: 19px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 14px;
    font-size: 10px;
   }
.checkmark-circle i {
    margin-top: 50px;
    margin-bottom: 40px;
    color: #509DC8;
    font-size: 18px;

   }
.contact-fcs {
    color: #91A6B2;
    font-size: 1.2em;
}
/*.col-lg-1, .col-lg-10, .col-lg-11, 
.col-lg-12, .col-lg-2, .col-lg-3, 
.col-lg-4, .col-lg-5, .col-lg-6, 
.col-lg-7, .col-lg-8, .col-lg-9, 
.col-md-1, .col-md-10, .col-md-11,
.col-md-12, .col-md-2, .col-md-3, 
.col-md-4, .col-md-5, .col-md-6, 
.col-md-7, .col-md-8, .col-md-9, 
.col-sm-1, .col-sm-10, .col-sm-11, 
.col-sm-12, .col-sm-2, .col-sm-3, 
.col-sm-4, .col-sm-5, .col-sm-6, 
.col-sm-7, .col-sm-8, .col-sm-9, 
.col-xs-1, .col-xs-10, .col-xs-11, 
.col-xs-12, .col-xs-2, .col-xs-3, 
.col-xs-4, .col-xs-5, .col-xs-6, 
.col-xs-7, .col-xs-8, .col-xs-9 {
    position: relative;
    min-height: 1px;
    padding-right: 0px;
    padding-left: 0px;
    margin-bottom: 0px;
}
*/

.login_form .col-sm-12 {
    padding-left: 0;
}

.contact_form .col-sm-5,
.login_form .col-sm-5 {
    width: 48.3%;
    padding-right: 0px;
    padding-left: 0px;
    margin-bottom: 0px;
}
.contact_form .col-sm-offset-1,
.login_form .col-sm-offset-1 {
    margin-left: 3.34% 
}

.contact_form .col-sm-2,
.login_form .col-sm-2 {
    width: 20%;
    padding-right: 0px;
    padding-left: 0px;
    margin-bottom: 0px;
}

.contact_form .col-sm-8,
.login_form .col-sm-8 {
    width: 80%;
    padding-right: 0px;
    padding-left: 0px;
    margin-bottom: 0px;
}

.contact_form .col-sm-offset-2,
.login_form .col-sm-offset-2 {
    margin-left: 20%;
}

.contact_info .col-sm-10,
.contact_info .col-sm-2 {
    padding-left: 10%;
    padding-right: 0;
}
.contact_info .col-sm-9 {
    padding:0 0;
}

.contact_info .col-sm-1 {
    padding:0;
}
.client-login {
    width: 23.44%;
    min-width: 280px;
    
    height: 44.31%;
    position: relative;
    display: block;
}

.logo_small{
    width: 100%;
    height: 18.67%;
    top:0;
    position: absolute;
    align-items: center;
    -ms-flex-pack: center;
    -ms-flex-align: center;
    justify-content: center;
    display: flex;
}

form.login_form {
    top:28.21%;
    position: absolute;
    width: 100%;
}
/*/*    align-items: center;
    -ms-flex-pack: center;/*Center horizontaly ie */
   /* -ms-flex-align:center;

    display: flex;
    top:0;
    left: 2.73%;
    position:absolute;
    z-index: 1000;*/




#fp-nav {
    position: fixed;
    z-index: 100;
    margin-top: -32px;
    top: 50%;
    opacity: 1;
    -webkit-transform: translate3d(0,0,0);
}
#fp-nav.right {
    right: 17px;
}
#fp-nav.left {
    left: 17px;
}
.fp-slidesNav{
    position: absolute;
    z-index: 4;
    left: 50%;
    opacity: 1;
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}
.fp-slidesNav.bottom {
    bottom: 17px;
}
.fp-slidesNav.top {
    top: 17px;
}
#fp-nav ul,
.fp-slidesNav ul {
  margin: 0;
  padding: 0;
}
#fp-nav ul li,
.fp-slidesNav ul li {
    display: block;
    width: 14px;
    height: 13px;
    margin: 7px;
    position:relative;
}
.fp-slidesNav ul li {
    display: inline-block;
}
#fp-nav ul li a,
.fp-slidesNav ul li a {
    display: block;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    text-decoration: none;
}
#fp-nav ul li a.active span,
.fp-slidesNav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li:hover a.active span{
    height: 12px;
    width: 12px;
    margin: -6px 0 0 -6px;
    border-radius: 100%;
 }
#fp-nav ul li a span,
.fp-slidesNav ul li a span {
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    height: 4px;
    width: 4px;
    border: 0;
    background: #509DC8;
    left: 50%;
    top: 50%;
    margin: -2px 0 0 -2px;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}
#fp-nav ul li:hover a span,
.fp-slidesNav ul li:hover a span{
    width: 10px;
    height: 10px;
    margin: -5px 0px 0px -5px;
}
#fp-nav ul li .fp-tooltip {
    position: absolute;
    top: -2px;
    color: #fff;
    font-size: 14px;
    font-family: arial, helvetica, sans-serif;
    white-space: nowrap;
    max-width: 220px;
    overflow: hidden;
    display: block;
    opacity: 0;
    width: 0;
    cursor: pointer;
}
#fp-nav ul li:hover .fp-tooltip,
#fp-nav.fp-show-active a.active + .fp-tooltip {
    -webkit-transition: opacity 0.2s ease-in;
    transition: opacity 0.2s ease-in;
    width: auto;
    opacity: 1;
}
#fp-nav ul li .fp-tooltip.right {
    right: 20px;
}
#fp-nav ul li .fp-tooltip.left {
    left: 20px;
}