
/* Custom template */

@font-face {
	font-family:"canoe-fonts";
	src: url("../fonts/canoe-fonts.eot?b7ac977e12c02d4ac809e2524a3d8eb3");
	src: url("../fonts/canoe-fonts.eot?#iefix") format("embedded-opentype"),
		url("../fonts/canoe-fonts.woff?b7ac977e12c02d4ac809e2524a3d8eb3") format("woff"),
		url("../fonts/canoe-fonts.ttf?b7ac977e12c02d4ac809e2524a3d8eb3") format("truetype");
	font-weight:normal;
	font-style:normal;
	}

.fcs {
	font-family:"canoe-fonts";
	display:inline-block;
	font-style:normal;
	speak:none;
	-webkit-font-smoothing:antialiased;
	}

/* Icons */
.fcs-account-dark:before { content:"\f101"; }
.fcs-account:before { content:"\f102"; }
.fcs-aligned-dark:before { content:"\f103"; }
.fcs-aligned:before { content:"\f104"; }
.fcs-angle-down-dark:before { content:"\f105"; }
.fcs-arrow-right:before { content:"\f106"; }
.fcs-asterisk:before { content:"\f107"; }
.fcs-calendar:before { content:"\f108"; }
.fcs-checkmark:before { content:"\f109"; }
.fcs-circle-checkbox-checked:before { content:"\f10a"; }
.fcs-circle-checkbox:before { content:"\f10b"; }
.fcs-circle-checkmark:before { content:"\f10c"; }
.fcs-circle:before { content:"\f10d"; }
.fcs-clip:before { content:"\f10e"; }
.fcs-collective-dollars:before { content:"\f10f"; }
.fcs-collective-resources:before { content:"\f110"; }
.fcs-columns:before { content:"\f111"; }
.fcs-cube:before { content:"\f112"; }
.fcs-dollar:before { content:"\f113"; }
.fcs-download:before { content:"\f114"; }
.fcs-email:before { content:"\f115"; }
.fcs-home:before { content:"\f116"; }
.fcs-intellectual-dark:before { content:"\f117"; }
.fcs-intellectual:before { content:"\f118"; }
.fcs-invite-user:before { content:"\f119"; }
.fcs-lightening:before { content:"\f11a"; }
.fcs-locate-us:before { content:"\f11b"; }
.fcs-location:before { content:"\f11c"; }
.fcs-logout:before { content:"\f11d"; }
.fcs-menu:before { content:"\f11e"; }
.fcs-notes-empty:before { content:"\f11f"; }
.fcs-notes-number:before { content:"\f120"; }
.fcs-notes:before { content:"\f121"; }
.fcs-paystub:before { content:"\f122"; }
.fcs-phone:before { content:"\f123"; }
.fcs-portage-partners:before { content:"\f124"; }
.fcs-preferences:before { content:"\f125"; }
.fcs-print:before { content:"\f126"; }
.fcs-question:before { content:"\f127"; }
.fcs-response-add:before { content:"\f128"; }
.fcs-response-alert:before { content:"\f129"; }
.fcs-response-ok:before { content:"\f12a"; }
.fcs-response:before { content:"\f12b"; }
.fcs-search:before { content:"\f12c"; }
.fcs-send:before { content:"\f12d"; }
.fcs-slide-down-dark:before { content:"\f12e"; }
.fcs-slide-down:before { content:"\f12f"; }
.fcs-slide-up-dark:before { content:"\f130"; }
.fcs-slide-up:before { content:"\f131"; }
.fcs-star-gray:before { content:"\f132"; }
.fcs-three-dots:before { content:"\f133"; }
.fcs-trash:before { content:"\f134"; }
.fcs-type-df-dark:before { content:"\f135"; }
.fcs-type-df:before { content:"\f136"; }
.fcs-type-di-dark:before { content:"\f137"; }
.fcs-type-di:before { content:"\f138"; }
.fcs-type-hf-dark:before { content:"\f139"; }
.fcs-type-hf:before { content:"\f13a"; }
.fcs-type-pl-dark:before { content:"\f13b"; }
.fcs-type-pl:before { content:"\f13c"; }
.fcs-type-re-dark:before { content:"\f13d"; }
.fcs-type-re:before { content:"\f13e"; }
.fcs-type-ss-dark:before { content:"\f13f"; }
.fcs-type-ss:before { content:"\f140"; }